import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_menu, {
      type: "overlay",
      side: "start",
      "menu-id": "first",
      "content-id": "main"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_ion_button, {
            fill: "clear",
            slot: "start",
            style: {"--background-hover-opacity":"0%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, {
                color: "dark",
                style: {"--background-hover-opacity":"0%"}
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_ion_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, {
              inset: "true",
              lines: "none",
              style: {"--ion-item-background":"transparent"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: "/about",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeMenu()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createTextVNode("About")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/contact",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeMenu()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Contact")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_router_outlet, { id: "main" })
  ], 64))
}