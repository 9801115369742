
import { IonContent, IonHeader, IonPage, IonToolbar, IonIcon, IonButton, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import { download } from 'ionicons/icons';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonIcon,
    IonButton,
    IonLabel
  },
  setup () {
    return {
      download
    }
  }
});
